import { storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';

export type StoredValue = StrBoolean;

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('27', t('screens:27.uuid'), val);
    if (val === 'yes') navigate('/session/28', { replace: true });
    if (val === 'no') navigate('/session/14', { replace: true });
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  useEffect(() => {
    props.useAudio('screens:27.audio');
  });

  return (
    <SessionScreen
      screen="27"
      title={`${t('screens:27.step')} ${t('screens:27.title')}`}
      chapter={2}
      progress={30}
      audio={t('screens:27.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:27.text')} />
      <div className="flex flex-col gap-2">
        <OptionField label={t('yes')} option="yes" value={val} onChange={setVal} />
        <OptionField label={t('no')} option="no" value={val} onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
