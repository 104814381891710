import { useEffect, useState } from 'react';

export default function useOrientation() {
  const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>();

  useEffect(() => {

    function updateOrientation() {
      setOrientation(window.innerWidth > window.innerHeight ? 'horizontal' : 'vertical');
    }

    window.addEventListener('resize', updateOrientation);
    window.addEventListener('orientationchange', updateOrientation);
    updateOrientation();

    return () => {
      window.removeEventListener('resize', updateOrientation);
      window.removeEventListener('orientationchange', updateOrientation);
    };
  });

  return orientation;
};
