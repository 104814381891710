import { storeAnswer } from "../../services/manager";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import TextField from "../../components/TextField";
import useValidation from "../../hooks/useValidation";

export type StoredValue = string;

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const validation = useValidation(val, "min:3|max_words:3");

  async function answer() {
    await storeAnswer(
      "34",
      t("screens:34.uuid"),
      validation.value as StoredValue
    );
    navigate("/session/39", { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:34.audio');
  });

  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        disabled={validation.failed}
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="34"
      title={`${t("screens:34.step")} ${t("screens:34.title")}`}
      chapter={2}
      progress={60}
      audio={t("screens:34.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:34.text")} />
      <TextField
        value={val}
        onChange={setVal}
        placeholder={t("write_your_answer_here")}
      />
    </SessionScreen>
  );
}
