import { getLatestScreenData } from '../services/manager';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoredValue as PreviousStoredValue34 } from '../screens/session/SessionScreen34';
import type { StoredValue as PreviousStoredValue36 } from '../screens/session/SessionScreen36';
import type { StoredValue as PreviousStoredValue37 } from '../screens/session/SessionScreen37';
import type { StoredValue as PreviousStoredValue40 } from '../screens/session/SessionScreen40';

export default function usePreviousNCValue(): string {

  const [nc, setNc] = useState('(unknown)');
  const { t } = useTranslation();

  useEffect(() => {
    (async function() {
      const previousData34 = await getLatestScreenData('34');
      const previousData36 = await getLatestScreenData('36');
      const previousData37 = await getLatestScreenData('37');
      const previousData40 = await getLatestScreenData('40');

      if (!previousData36?.answer) return;

      // Count the number of 'yes' of screen 36.
      const ncOptions = previousData36.answer as PreviousStoredValue36;
      let count = Object.values(ncOptions).filter(v => v === 'yes').length;

      // Increase the count by 1 when answer of screen 37 is 'yes'.
      const ncOptionsInput = previousData37?.answer as PreviousStoredValue37;
      count += Object.values(ncOptionsInput).filter(v => v === 'yes').length;
      // When all answers are 'no', we take the answer of screen 34.
      if (count === 0) {
        if (previousData34?.answer) {
          return setNc(previousData34.answer as PreviousStoredValue34);
        }

      } else if (count === 1) {
        // When an option is answered with 'yes' in screen 36, we use that.
        if (previousData36?.answer) {
          const items = Object.entries(ncOptions)
            .filter(([k, v]) => v === 'yes')
            .map(([k]) => k);

          if (items.length > 0) {
            return setNc(t(items[0]));
          }
          else {
            const items = Object.entries(ncOptionsInput)
            .filter(([k, v]) => v === 'yes')
            .map(([k]) => k);

            if (items.length > 0) {
              return setNc(t(items[0]));
            }
          }
        }
      } else {
        // When all answers are 'no', use the answer of screen 40.
        if (previousData40?.answer) {
          return setNc(previousData40.answer as PreviousStoredValue40);
        }
      }
    })();
  }, [t, nc]);

  return nc;
}
