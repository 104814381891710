import { storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('20', t('screens:20.uuid'), val);
    if (val === 'no_only_this_picture') navigate('/session/21', { replace: true });
    if (val === 'there_are_more_pictures') navigate('/session/12', { replace: true });
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  useEffect(() => {
    props.useAudio('screens:20.audio');
  });

  return (
    <SessionScreen
      screen="20"
      title={`${t('screens:20.step')} ${t('screens:20.title')}`}
      chapter={2}
      progress={40}
      audio={t('screens:20.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:20.text')} />
      <div className="flex flex-col gap-2">
        <OptionField label={t('no_only_this_picture')} option="no_only_this_picture" value={val} onChange={setVal} />
        <OptionField label={t('there_are_more_pictures')} option="there_are_more_pictures" value={val} onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
