import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";

export default function UserAgreementScreen() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  async function handleStartSession() {
    navigate("/session/welcome", { replace: true });
  }

  function getTranslatedUserAgreement() {
    return `<div classname="disclaimer">${t(
      "moovd_user_rights_agreement"
    )}</div>
    `;
  }

  return (
    <div className="min-h-screen pt-8 p-6 bg-babyblue">
      <h1 className="text-center text-2xl font-bold text-white mb-6 after:border-b after:block after:w-10 after:mx-auto after:pt-8">
        {t("screens:user_rights_agreement.title")}
      </h1>
      <div className="p-6 bg-white shadow-xl min-h-screen-card flex flex-col justify-between">
        <div className="flex-grow h-104 scroll-auto overflow-scroll">
          <Markdown value={getTranslatedUserAgreement()} />
        </div>
      </div>
      <div className="flex-none mt-3 flex gap-2 justify-end">
        <Button
          label={t("go_back")}
          variant="quaternary"
          onClick={handleStartSession}
        />
      </div>
    </div>
  );
}
