import { ReactFragment, ReactNode } from 'react';
import Portal from './Portal';

type Props = {
  actions?: ReactFragment;
  children: ReactNode;
}

export default function Dialog({ actions, children }: Props) {
  return (
    <Portal>
      <div className="z-30 flex fixed items-center inset-0 overflow-auto p-6 bg-blue/50">
        <div className="bg-white p-6 shadow-2xl mx-auto w-full max-w-sm">
          <div className="text-lg">
            {children}
          </div>

          { actions &&
            <div className="mt-6 flex-none flex gap-2 justify-end">
              {actions}
            </div>
          }
        </div>
      </div>
    </Portal>
  );
}
