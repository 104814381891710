import { getLatestScreenData, storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import ScaleOptionField from '../../components/ScaleOptionField';
import SessionScreen from './SessionScreen';
import type { StoredValue as PreviousStoredValue } from './SessionScreen42';
import useIsPowerlessNC from '../../hooks/useIsPowerlessNC';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPowerlessNC = useIsPowerlessNC();
  const [val, setVal] = useState('');
  const [pc, setPCValue] = useState<string>();

  async function answer() {
    await storeAnswer('43', t('screens:43.uuid'), val);
    navigate('/session/44', { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:43.audio');
    (async function() {
      //if (isPowerlessNC) {
      //  return setPCValue(t('i_can_handle_it_looking_at_the_image'));
      //}

      const previousData = await getLatestScreenData('42');
      if (previousData?.answer) {
        switch (previousData.answer as PreviousStoredValue) {
          case 'safety':
            return setPCValue(t('i_am_currently_safe'));
          case 'self_esteem':
            return setPCValue(t('i_am_worthy'));
          case 'responsibility':
            return setPCValue(t('i_did_what_i_could'));
          case 'ok':
            return setPCValue(t('i_am_ok'));
          case 'competent':
            return setPCValue(t('i_am_competent'));
          case 'powerless':
            return setPCValue(t('i_can_handle_it_looking_at_the_image'));
        }
      }
    })();
  }, [t, isPowerlessNC]);

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="43"
      title={`${t('screens:43.step')} ${t('screens:43.title')}`}
      chapter={2}
      progress={75}
      audio={t('screens:43.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:43.text', { pc })} />

      <div className="mt-10 flex justify-between">
        <ScaleOptionField label="1" option="1" value={val} group="scale" onChange={setVal} />
        <ScaleOptionField label="2" option="2" value={val} group="scale" onChange={setVal} />
        <ScaleOptionField label="3" option="3" value={val} group="scale" onChange={setVal} />
        <ScaleOptionField label="4" option="4" value={val} group="scale" onChange={setVal} />
        <ScaleOptionField label="5" option="5" value={val} group="scale" onChange={setVal} />
        <ScaleOptionField label="6" option="6" value={val} group="scale" onChange={setVal} />
        <ScaleOptionField label="7" option="7" value={val} group="scale" onChange={setVal} />
      </div>
      <div className="mt-4 flex gap-4 justify-between text-sm font-light leading-none italic">
        <div className="whitespace-pre-wrap">{t('completely_unbelievable')}</div>
        <div className="border-t border-blue/30 flex-1 self-center"></div>
        <div className="whitespace-pre-wrap text-right">{t('completely_believable')}</div>
      </div>
    </SessionScreen>
  );
};
