import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import { useEffect } from 'react';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer('9', t('screens:9.uuid'));
    navigate('/session/23');
  }

  async function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    props.useAudio('screens:9.audio');
  });

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="9"
      title={`${t('screens:9.step')} ${t('screens:9.title')}`}
      chapter={1}
      progress={100}
      audio={t('screens:9.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:9.text')} />
    </SessionScreen>
  );
};
