import { storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('76', t('screens:76.uuid'), val);
    if (val === 'yes') navigate('/session/75', { replace: true });
    if (val === 'no') navigate('/session/77', { replace: true });
  }
  useEffect(() => {
    props.useAudio('screens:76.audio');
  });

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="76"
      title={`${t('screens:76.step')} ${t('screens:76.title')}`}
      chapter={6}
      progress={0}
      audio={t('screens:76.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:76.text')} />
      <div className="flex flex-col gap-2">
        <OptionField label={t('yes')} option="yes" value={val} onChange={setVal} />
        <OptionField label={t('no')} option="no" value={val} onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
