import { getLatestScreenData, storeAnswer } from "../../services/manager";
import { isString } from "../../services/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import TextPreview from "../../components/TextPreview";

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState("");

  useEffect(() => {
    getLatestScreenData("29").then((data) => {
      if (data?.answer && isString(data.answer)) {
        setVal(data.answer as string);
      }
    });
  });

  async function answerYes() {
    await storeAnswer("30", t("screens:30.uuid"), "yes");
    navigate("/session/22", { replace: true });
  }

  async function answerNo() {
    await storeAnswer("30", t("screens:30.uuid"), "no");
    navigate("/session/28", { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:30.audio');
  });

  const actions = (
    <>
      <Button label={t("no")} variant="secondary" onClick={() => answerNo()} />
      <Button
        label={t("yes_continue")}
        variant="primary"
        onClick={() => answerYes()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="30"
      title={`${t("screens:30.step")} ${t("screens:30.title")}`}
      chapter={2}
      progress={35}
      audio={t("screens:30.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:30.text")} />
    </SessionScreen>
  );
}
