import { getLatestScreenData } from '../services/manager';
import { useEffect, useState } from 'react';
import type { StoredValue as PreviousStoredValue } from '../screens/session/SessionScreen36';

export default function useIsPowerlessNC() {

  const [isPowerless, setPowerless] = useState<boolean>(false);

  useEffect(() => {
    (async function() {
      const previousData40 = await getLatestScreenData('40');
      if (previousData40?.answer && previousData40.answer === 'powerless') {
        return setPowerless(true);
      }

      const previousData36 = await getLatestScreenData('36');
      if (previousData36?.answer) {
        const answers = previousData36.answer as PreviousStoredValue;

        const isPowerless = Object.entries(answers)
          .filter(([k, v]) => k === 'powerless' && v === 'yes')
          .length === 1;

        const count = Object.values(answers).filter(v => v === 'yes').length;
        if (count === 1 && isPowerless) {
          return setPowerless(true);
        }
      }
    })();
  }, [isPowerless]);

  return isPowerless;
}
