type Props = {
  src: string;
  poster: string;
}

export default function VideoPlayer({ src, poster }: Props) {
  return (
      <video controls disablePictureInPicture width="-translate-x-1/2" height="-translate-y-1/2"
        controlsList="nodownload noplaybackrate"
        poster = {poster}
      >
        <source src={src} type="video/mp4" />
      </video>
  );
}
