import { getLatestScreenData } from '../services/manager';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoredValue as PreviousStoredValue78 } from '../screens/session/SessionScreen78';

export default function usePreviousNCValue() {

  const [previousValue, setPreviousValue] = useState('(unknown)');
  const { t } = useTranslation();

  useEffect(() => {
    (async function() {
      const previousData78 = await getLatestScreenData('78');

      if (!previousData78?.answer) return;

      if (previousData78?.answer) {
        return setPreviousValue(previousData78.answer as PreviousStoredValue78);
      }
    })();
  }, [t, previousValue]);

  return { previousValue, setPreviousValue };
}
