import { useEffect, useState } from 'react';

type Props = {
  time: number;
  delay: number;
  onEndTime(): void;
}

export default function TimeProgressbar({ time, delay = 1, onEndTime }: Props) {
  const [started, setStarted] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setTimeout(() => setStarted(true), delay * 1000);
  }, [delay]);

  useEffect(() => {
    if (!started) return;

    const interval = setInterval(() => {
      setProgress(progress + 1);
      if (progress >= time) {
        clearInterval(interval);
        onEndTime();
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className="h-6 bg-lightgray rounded overflow-hidden relative">
      <div className="h-full bg-blue rounded transition-size duration-1000 ease-linear" style={{ width: `${progress / time * 100}%` }}></div>
    </div>
  );
};
