import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import ScaleOptionField from '../../components/ScaleOptionField';
import SessionScreen from './SessionScreen';
import usePreviousNCValue from '../../hooks/usePreviousNCValue';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nc = usePreviousNCValue();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('45', t('screens:45.uuid'), val);
    navigate('/session/47', { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:45.audio');
  });

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="45"
      title={`${t('screens:45.step')} ${t('screens:45.title')}`}
      chapter={2}
      progress={85}
      audio={t('screens:45.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:45.text', { nc: t(nc) })} />

      <div className="max-w-16rem smt-10 flex flex-wrap justify-center gap-3">
        <ScaleOptionField label="0" option="0" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="1" option="1" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="2" option="2" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="3" option="3" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="4" option="4" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="5" option="5" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="6" option="6" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="7" option="7" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="8" option="8" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="9" option="9" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="10" option="10" value={val} variant="secondary" group="scale" onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
