import { getLatestScreenData, storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';
import type { StoredValue as PreviousStoredValue } from './SessionScreen36';

export type StoredValue = {
  worthless: StrBoolean;
  fool: StrBoolean;
  badPerson: StrBoolean;
  weak: StrBoolean;
  coward: StrBoolean;
};

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [worthless, setWorthless] = useState('');
  const [fool, setFool] = useState('');
  const [badPerson, setBadPerson] = useState('');
  const [weak, setWeak] = useState('');
  const [coward, setCoward] = useState('');

  async function answer() {

    const answers: StoredValue = {
      worthless: (worthless === 'yes' ? 'yes' : 'no'),
      fool: (fool === 'yes' ? 'yes' : 'no'),
      badPerson: (badPerson === 'yes' ? 'yes' : 'no'),
      weak: (weak === 'yes' ? 'yes' : 'no'),
      coward: (coward === 'yes' ? 'yes' : 'no'),
    };

    await storeAnswer('37', t('screens:37.uuid'), answers);
    const previousData = await getLatestScreenData('36');
    const count36 = Object.values(answers).filter(v => v === 'yes').length;
    const answers36 = previousData?.answer as PreviousStoredValue;
    const count37= Object.values(answers36).filter(v => v === 'yes').length;
    const totalCount = count36 + count37;

    if (totalCount === 0) {
      navigate('/session/34', { replace: true });
    }
    else if (totalCount === 1) {
      navigate('/session/39', { replace: true });
    }
    else // (totalCount > 1)
    {
      navigate('/session/40', { replace: true });
    }
  }

  const actions = (<>
    <Button 
    label={t('continue')} 
    variant="primary" 
    disabled={worthless === "" || fool === "" || badPerson === "" || weak === "" || coward === ""}
    onClick={() => answer()} 
    />
  </>);
  
  useEffect(() => {
    props.useAudio('screens:37.audio');
  });
  const overrides = {
    p: {
      props: {
        className: 'mb-2 font-bold' ,
      },
    },
  }



  return (
    <SessionScreen
      screen="37"
      title={`${t('screens:37.step')} ${t('screens:37.title')}`}
      chapter={2}
      progress={55}
      audio={t('screens:37.audio')}
      actions={actions}
    >
      <Markdown  value={t('screens:37.text')} />
      
      <Markdown overrides={overrides} value={t('screens:37.text1')} />
      <div className="flex gap-2 mb-2">
        <OptionField label={t('yes')} size="small" option="yes" value={worthless} group="x" onChange={setWorthless} />
        <OptionField label={t('no')} size="small" option="no" value={worthless} group="x" onChange={setWorthless} />
      </div>

      <Markdown overrides={overrides} value={t('screens:37.text2')} />
      <div className="flex gap-2 mb-2">
        <OptionField label={t('yes')} size="small" option="yes" value={fool} group="x" onChange={setFool} />
        <OptionField label={t('no')} size="small" option="no" value={fool} group="x" onChange={setFool} />
      </div>

      <Markdown overrides={overrides} value={t('screens:37.text3')} />
      <div className="flex gap-2 mb-2">
        <OptionField label={t('yes')} size="small" option="yes" value={badPerson} group="x" onChange={setBadPerson} />
        <OptionField label={t('no')} size="small" option="no" value={badPerson} group="x" onChange={setBadPerson} />
      </div>

      <Markdown overrides={overrides} value={t('screens:37.text4')} />
      <div className="flex gap-2 mb-2">
        <OptionField label={t('yes')} size="small" option="yes" value={weak} group="x" onChange={setWeak} />
        <OptionField label={t('no')} size="small" option="no" value={weak} group="x" onChange={setWeak} />
      </div>

      <Markdown overrides={overrides} value={t('screens:37.text5')} />
      <div className="flex gap-2 mb-2">
        <OptionField label={t('yes')} size="small" option="yes" value={coward} group="x" onChange={setCoward} />
        <OptionField label={t('no')} size="small" option="no" value={coward} group="x" onChange={setCoward} />
      </div>
    </SessionScreen>
  );
};
