import type { TOptions } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ValidationError = {
  key: string;
  options: TOptions;
};

export type ValidationData = {
  validator: string;
  error: ValidationError | null;
  passes: boolean;
};

/**
 * Normalizes all multiple whitespace characters into a single space.
 *
 * @param value string
 * @returns string
 */
export function normalizeWhitespace(value: string): string {
  return value.replace(/\s+/g, ' ').trim();
}

/**
 * Min value validator.
 * Signature: min:1
 *
 * @param value string
 * @param min number default: 1
 * @returns ValidationData
 */
export function minValue(value: string, min: number = 1): ValidationData {
  const validator = 'min';
  const passes = normalizeWhitespace(value).length >= min;
  const error = !passes ? {
    key: `validation.${validator}`,
    options: { count: min },
  } : null;

  return { validator, error, passes };
}

/**
 * Size value validator.
 * Signature: size:1
 *
 * @param value string
 * @param size number default: 1
 * @returns ValidationData
 */
 export function sizeValue(value: string, size: number = 1): ValidationData {
  const validator = 'size';
  const passes = normalizeWhitespace(value).length === size;
  const error = !passes ? {
    key: `validation.${validator}`,
    options: { count: size },
  } : null;

  return { validator, error, passes };
}

/**
 * Max value validator.
 * Signature: max:1
 *
 * @param value string
 * @param max number default: 1
 * @returns ValidationData
 */
export function maxValue(value: string, max: number = 1): ValidationData {
  const validator = 'max';
  const passes = normalizeWhitespace(value).length <= max;
  const error = !passes ? {
    key: `validation.${validator}`,
    options: { count: max },
   } : null;

  return { validator, error, passes };
}

/**
 * Numeric value validator.
 * Signature: numeric
 *
 * @param value string
 * @param max number default: 1
 * @returns ValidationData
 */
export function numericValue(value: string): ValidationData {
  const validator = 'numeric';
  const passes = /^[0-9]+$/ig.test(normalizeWhitespace(value));
  const error = !passes ? {
    key: `validation.${validator}`,
    options: {},
  } : null;

  return { validator, error, passes };
}

/**
 * Max words validator.
 * Signature: max_words:1
 *
 * @param value string
 * @param max number default: 1
 * @returns ValidationData
 */
export function maxWords(value: string, max: number = 1): ValidationData {
  const validator = 'max_words';
  const words = normalizeWhitespace(value).split(' ');
  const passes = words !== undefined && words !== null && words.length <= max;
  const error = !passes ? {
    key: `validation.${validator}`,
    options: { count: max }
  } : null;

  return { validator, error, passes };
}

/**
 * Execute validation.
 *
 * @param value string input
 * @param conditions string ex. min:10|max_words:5
 * @returns ValidationData[]
 */
export function validate(value: string, conditions: string): Array<ValidationData> {
  const states = conditions.split('|').map(condition => {
    const rule = condition.split(':');
    const validator = rule[0];
    const params = rule.slice(1);

    switch (validator) {
      case 'size':
        if (params.length > 0) {
          return sizeValue(value, parseInt(params[0]));
        }
        break;
      case 'min':
        if (params.length > 0) {
          return minValue(value, parseInt(params[0]));
        }
        break;
      case 'max':
        if (params.length > 0) {
          return maxValue(value, parseInt(params[0]));
        }
        break;
      case 'max_words':
        if (params.length > 0) {
          return maxWords(value, parseInt(params[0]));
        }
        break;
      case 'numeric':
        return numericValue(value);
    }
    return undefined;
  });

  return states.filter(state => state !== undefined) as NonNullable<ValidationData[]>;
}

/**
 * Use validation
 *
 * @param value
 * @param conditions ex. min:10|max_words:5
 */
export default function useValidation(value: string, conditions: string) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [passed, setPassed] = useState(false);
  const [status, setStatus] = useState<ValidationData[]>([]);

  useEffect(() => {
    const results = validate(value, conditions);
    setStatus(results);
    setErrors(
      results.map(data => data.error)
        .filter(error => error !== null)
        .map(error => error && t(error.key, error.options)) as NonNullable<string[]> // translate error messages
    );
    setPassed(results.every(result => result.passes));
  }, [value, conditions, t]);

  return {
    errors,
    error: errors.length > 0 ? errors.slice(0, 1)[0] : null,
    failed: !passed,
    passed,
    status,
    value: normalizeWhitespace(value),
  }
};
