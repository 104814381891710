import { storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('48', t('screens:48.uuid'), val);
    if (val === 'i_dont_feel_any_tension') navigate('/session/rotate-instruction', { replace: true });
    if (val === 'i_feel_tension_somewhere') navigate('/session/47', { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:48.audio');
  });
  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="48"
      title={`${t('screens:48.step')} ${t('screens:48.title')}`}
      chapter={2}
      progress={100}
      audio={t('screens:48.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:48.text')} />
      <div className="flex flex-col gap-2">
        <OptionField label={t('i_dont_feel_any_tension')} option="i_dont_feel_any_tension" value={val} onChange={setVal} />
        <OptionField label={t('i_feel_tension_somewhere')} option="i_feel_tension_somewhere" value={val} onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
