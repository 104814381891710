import { getLatestScreenData, storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';
import type { StoredValue as PreviousStoredValue } from './SessionScreen27';

export type StoredValue = {
  powerless: StrBoolean;
  unsafe: StrBoolean;
  guilty: StrBoolean;
};

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [powerless, setPowerless] = useState('');
  const [unsafe, setUnsafe] = useState('');
  const [guilty, setGuilty] = useState('');

  const [isGuiltyInFront, setGuiltyInFront] = useState<boolean>(false);

  useEffect(() => {
    (async function() {
      const previousData = await getLatestScreenData('27');
      const previousValue = previousData?.answer as PreviousStoredValue;
      setGuiltyInFront(previousValue === 'yes');
    })();
  }, []);

  async function answer() {
    const answers: StoredValue = {
      powerless: (powerless === 'yes' ? 'yes' : 'no'),
      unsafe: (unsafe === 'yes' ? 'yes' : 'no'),
      guilty: (guilty === 'yes' ? 'yes' : 'no'),
    };

    await storeAnswer('36', t('screens:36.uuid'), answers);
    navigate('/session/37', { replace: true });
  }

  function getAudio() {

  }

  useEffect(() => {
    if (isGuiltyInFront) {
      props.useAudio('screens:36.audioGuilty');
    } else {
      props.useAudio('screens:36.audio');
    }
  });

  const actions = (<>
    <Button 
    label={t('continue')} 
    variant="primary" 
    onClick={() => answer()} 
    disabled={checkDisabled()}
    />
  </>);

  function checkDisabled(): boolean {
    if (isGuiltyInFront) {
      return guilty === "";
    }
    return powerless === "" || unsafe === "" || guilty === "";
  }

  return (
    <SessionScreen
      screen="36"
      title={`${t('screens:36.step')} ${t('screens:36.title')}`}
      chapter={2}
      progress={55}
      actions={actions}
    >
      {!isGuiltyInFront && <>
        <Markdown value={t('screens:36.text')} />
        <div className="flex gap-2 mb-4">
          <OptionField label={t('yes')} size="small" option="yes" value={powerless} group="x" onChange={setPowerless} />
          <OptionField label={t('no')} size="small" option="no" value={powerless} group="x" onChange={setPowerless} />
        </div>

        <Markdown value={t('screens:36.text2')} />
        <div className="flex gap-2 mb-4">
          <OptionField label={t('yes')} size="small" option="yes" value={unsafe} group="x" onChange={setUnsafe} />
          <OptionField label={t('no')} size="small" option="no" value={unsafe} group="x" onChange={setUnsafe} />
        </div>
      </>}

      <Markdown value={t('screens:36.text3')} />
      <div className="flex gap-2 mb-4">
        <OptionField label={t('yes')} size="small" option="yes" value={guilty} group="x" onChange={setGuilty} />
        <OptionField label={t('no')} size="small" option="no" value={guilty} group="x" onChange={setGuilty} />
      </div>
    </SessionScreen>
  );
};
