import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import { storeAnswer } from "../../services/manager";
import { useEffect } from "react";

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer("11", t("screens:11.uuid"));
    navigate("/session/20", { replace: true });
  }

  async function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    props.useAudio('screens:11.audio');
  });

  const actions = (
    <>
      <Button
        label={t("go_back")}
        variant="secondary"
        onClick={() => goBack()}
      />
      <Button
        label={t("continue")}
        variant="primary"
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="11"
      title={`${t("screens:11.step")} ${t("screens:11.title")}`}
      chapter={2}
      progress={40}
      audio={t("screens:11.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:11.text")} />
    </SessionScreen>
  );
}
