import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import TimeProgressbar from '../../components/TimeProgressbar';
import { useEffect } from 'react';
import Button from '../../components/Button';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [btnActive, setBtnActive] = useState(false);

  async function onEndTime() {
    setBtnActive(true);
  }

  const actions = (<>
    <Button label={t('continue')}
     variant="primary" 
     disabled={!btnActive}
     onClick={() => answer()} />
  </>);

  async function answer() {
    await storeAnswer('75', t('screens:75.uuid'));
    navigate('/session/76', { replace: true });
    return;
  }
  useEffect(() => {
    props.useAudio('screens:75.audio');
  });

  return (
    <SessionScreen
      screen="75"
      title={`${t('screens:75.step')} ${t('screens:75.title')}`}
      chapter={6}
      progress={0}
      audio={t('screens:75.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:75.text')} />
      <TimeProgressbar delay={3} time={10} onEndTime={onEndTime} />
    </SessionScreen>
  );
};
