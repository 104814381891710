import { useOutletContext, useParams, Outlet } from "react-router-dom";
import AudioButton from "../../components/AudioButton";
import { useTranslation } from "react-i18next";
import SessionScreen06 from "./SessionScreen06";
import SessionScreen07 from "./SessionScreen07";
import SessionScreen08 from "./SessionScreen08";
import SessionScreen09 from "./SessionScreen09";
import SessionScreen11 from "./SessionScreen11";
import SessionScreen12 from "./SessionScreen12";
import SessionScreen13 from "./SessionScreen13";
import SessionScreen14 from "./SessionScreen14";
import SessionScreen15 from "./SessionScreen15";
import SessionScreen17 from "./SessionScreen17";
import SessionScreen18 from "./SessionScreen18";
import SessionScreen19 from "./SessionScreen19";
import SessionScreen20 from "./SessionScreen20";
import SessionScreen21 from "./SessionScreen21";
import SessionScreen22 from "./SessionScreen22";
import SessionScreen23 from "./SessionScreen23";
import SessionScreen25 from "./SessionScreen25";
import SessionScreen26 from "./SessionScreen26";
import SessionScreen27 from "./SessionScreen27";
import SessionScreen28 from "./SessionScreen28";
import SessionScreen30 from "./SessionScreen30";
import SessionScreen32 from "./SessionScreen32";
import SessionScreen33 from "./SessionScreen33";
import SessionScreen34 from "./SessionScreen34";
import SessionScreen35 from "./SessionScreen35";
import SessionScreen36 from "./SessionScreen36";
import SessionScreen37 from "./SessionScreen37";
import SessionScreen39 from "./SessionScreen39";
import SessionScreen40 from "./SessionScreen40";
import SessionScreen42 from "./SessionScreen42";
import SessionScreen43 from "./SessionScreen43";
import SessionScreen44 from "./SessionScreen44";
import SessionScreen45 from "./SessionScreen45";
import SessionScreen47 from "./SessionScreen47";
import SessionScreen48 from "./SessionScreen48";
import SessionScreen74 from "./SessionScreen74";
import SessionScreen75 from "./SessionScreen75";
import SessionScreen76 from "./SessionScreen76";
import SessionScreen77 from "./SessionScreen77";
import SessionScreen78 from "./SessionScreen78";
import SessionScreen80 from "./SessionScreen80";
import SessionScreen81 from "./SessionScreen81";
import { useState } from "react";
import SessionScreen82 from "./SessionScreen82";

export default function SessionStepWrapper() {
  const { activeStep } = useParams();
  const { t } = useTranslation();
  const [audioLink, setAudio] = useState("");

  const useAudio = (audioLink: string) => {
    setAudio(audioLink);
  };

  let link =
    "https://storage.googleapis.com/moovd-apps/moovd-emdr-auto/READ_ME_INSTRUCTIONS/";

  if (localStorage.getItem("i18nextLng")?.includes("en")) {
    link += "ENG/";
  }

  if (localStorage.getItem("i18nextLng")?.includes("nl")) {
    link += "NL/";
  }

  // TODO: if German file is available, change to DU/
  if (localStorage.getItem("i18nextLng")?.includes("de")) {
    link += "ENG/";
  }

  // just to make sure if line:61-70 code error, uncomment this code
  // link += localStorage.getItem("i18nextLng")?.includes("en") ? "ENG/" : "NL/";
  const voice = "j.vanzon/";

  const renderStep = () => {
    switch (activeStep) {
      case "6":
        return <SessionScreen06 useAudio={useAudio} />;
      case "7":
        return <SessionScreen07 useAudio={useAudio} />;
      case "8":
        return <SessionScreen08 useAudio={useAudio} />;
      case "9":
        return <SessionScreen09 useAudio={useAudio} />;
      // case "10": return <SessionScreen10 useAudio={useAudio}/>;
      case "11":
        return <SessionScreen11 useAudio={useAudio} />;
      case "12":
        return <SessionScreen12 useAudio={useAudio} />;
      case "13":
        return <SessionScreen13 useAudio={useAudio} />;
      case "14":
        return <SessionScreen14 useAudio={useAudio} />;
      case "15":
        return <SessionScreen15 useAudio={useAudio} />;
      // case "16": return <SessionScreen16 />;
      case "17":
        return <SessionScreen17 useAudio={useAudio} />;
      case "18":
        return <SessionScreen18 useAudio={useAudio} />;
      case "19":
        return <SessionScreen19 useAudio={useAudio} />;
      case "20":
        return <SessionScreen20 useAudio={useAudio} />;
      case "21":
        return <SessionScreen21 useAudio={useAudio} />;
      case "22":
        return <SessionScreen22 useAudio={useAudio} />;
      case "23":
        return <SessionScreen23 useAudio={useAudio} />;
      // case "24": return <SessionScreen24 useAudio={useAudio}/>;
      case "25":
        return <SessionScreen25 useAudio={useAudio} />;
      case "26":
        return <SessionScreen26 useAudio={useAudio} />;
      case "27":
        return <SessionScreen27 useAudio={useAudio} />;
      case "28":
        return <SessionScreen28 useAudio={useAudio} />;
      // case "29": return <SessionScreen29 useAudio={useAudio}/>;
      case "30":
        return <SessionScreen30 useAudio={useAudio} />;
      case "32":
        return <SessionScreen32 useAudio={useAudio} />;
      case "33":
        return <SessionScreen33 useAudio={useAudio} />;
      case "34":
        return <SessionScreen34 useAudio={useAudio} />;
      case "35":
        return <SessionScreen35 useAudio={useAudio} />;
      case "36":
        return <SessionScreen36 useAudio={useAudio} />;
      case "37":
        return <SessionScreen37 useAudio={useAudio} />;
      case "39":
        return <SessionScreen39 useAudio={useAudio} />;
      case "40":
        return <SessionScreen40 useAudio={useAudio} />;
      // case "41": return <SessionScreen41 useAudio={useAudio}/>;
      case "42":
        return <SessionScreen42 useAudio={useAudio} />;
      case "43":
        return <SessionScreen43 useAudio={useAudio} />;
      case "44":
        return <SessionScreen44 useAudio={useAudio} />;
      case "45":
        return <SessionScreen45 useAudio={useAudio} />;
      case "47":
        return <SessionScreen47 useAudio={useAudio} />;
      case "48":
        return <SessionScreen48 useAudio={useAudio} />;
      case "74":
        return <SessionScreen74 useAudio={useAudio} />;
      case "75":
        return <SessionScreen75 useAudio={useAudio} />;
      case "76":
        return <SessionScreen76 useAudio={useAudio} />;
      case "77":
        return <SessionScreen77 useAudio={useAudio} />;
      case "78":
        return <SessionScreen78 useAudio={useAudio} />;
      case "80":
        return <SessionScreen80 useAudio={useAudio} />;
      case "81":
        return <SessionScreen81 useAudio={useAudio} />;
      case "82":
        return <SessionScreen82 useAudio={useAudio} />;

      default:
        return <h1>No step match</h1>;
    }
  };
  return (
    <div className="relative px-6 pb-6">
      <div className="p-6 bg-white shadow-xl min-h-screen-card flex flex-col justify-between">
        <div className="flex-none mb-6">
          <AudioButton src={link + voice + t(audioLink)} />
        </div>
        {renderStep()}
      </div>
    </div>
  );
}
