/**
 * Check if the given value is a string.
 *
 * @param value
 * @returns boolean
 */
export function isString(value: unknown): boolean {
  return Object.prototype.toString.call(value) === '[object String]';
}

/**
 * Check if the given value is a plain object.
 *
 * @param value
 * @returns boolean
 */
export function isPlainObject(value: unknown): boolean {
	if (Object.prototype.toString.call(value) !== '[object Object]') {
		return false;
	}

	const prototype = Object.getPrototypeOf(value);
	return prototype === null || prototype === Object.prototype;
}
