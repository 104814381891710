import { getLatestScreenData, storeAnswer } from "../../services/manager";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import OptionField from "../../components/OptionField";
import SessionScreen from "./SessionScreen";
import type { StoredValue as PreviousStoredValueNCs } from "./SessionScreen36";
import type { StoredValue as PreviousStoredValueCustomNCs } from "./SessionScreen37";

export type StoredValue = string;

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const [ncs, setNCs] = useState<Array<keyof PreviousStoredValueNCs>>([]);
  const [customNcs, setCustomNC] = useState<Array<keyof PreviousStoredValueCustomNCs>>([]);

  async function answer() {
    await storeAnswer("40", t("screens:40.uuid"), val as StoredValue);
    navigate("/session/39", { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:40.audio');
    (async function () {
      const previousDataNCs = await getLatestScreenData("36");
      if (previousDataNCs?.answer) {
        const ncs = Object.entries(
          previousDataNCs.answer as PreviousStoredValueNCs
        )
          .filter(([k, v]) => v === "yes")
          .map(([k, v]) => k as keyof PreviousStoredValueNCs);

        setNCs(ncs);
      }
      const previousDataCustomNCs = await getLatestScreenData("37");
      if (previousDataCustomNCs?.answer) {
        const ncs = Object.entries(
          previousDataCustomNCs.answer as PreviousStoredValueCustomNCs
        )
          .filter(([k, v]) => v === "yes")
          .map(([k, v]) => k as keyof PreviousStoredValueCustomNCs);

          setCustomNC(ncs);
      }
    })();
  }, []);

  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        disabled={val === ""}
        onClick={() => answer()}
      />
    </>
  );

  const overrides = {
    p: {
      props: {
        className: 'mb-1' ,
      },
    },
  };

  return (
    <SessionScreen
      screen="40"
      title={`${t("screens:40.step")} ${t("screens:40.title")}`}
      chapter={2}
      progress={60}
      audio={t("screens:40.audio")}
      actions={actions}
    >
      <Markdown overrides={overrides}
        value={t("screens:40.text", {
          ncs: [
            ...ncs.map((value) => t("i_am_value", { value: t(value) })),
            ...customNcs.map((value) => t("i_am_value", { value: t(value) })),
          ]
            .filter((v) => v !== null)
            .join(",\n\n "),
        })}
      />

      <div className="flex flex-col gap-2">
        {ncs.map((value) => {
          return (
            <OptionField
              key={value}
              label={t("i_am_value", { value: t(value) })}
              option={value}
              value={val}
              onChange={setVal}
            />
          );
        })}
        {customNcs.map((value) => {

          if (value.includes("badPerson")) {
            return (
              <OptionField
                key={value}
                label={t("i_am_value_a", { value: t(value) })}
                option={value}
                value={val}
                onChange={setVal}
              />
            );
          }
          else {
            return (
              <OptionField
                key={value}
                label={t("i_am_value", { value: t(value) })}
                option={value}
                value={val}
                onChange={setVal}
              />
            );
          }
        })}
      </div>
    </SessionScreen>
  );
}
