import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import { storeAnswer } from "../../services/manager";
import { useEffect } from "react";

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer("18", t("screens:18.uuid"));
    navigate("/session/19", { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:18.audio');
  });

  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="18"
      title={`${t("screens:18.step")} ${t("screens:18.title")}`}
      chapter={2}
      progress={40}
      audio={t("screens:18.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:18.text")} />
    </SessionScreen>
  );
}
