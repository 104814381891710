import { storeAnswer, getLatestScreenData } from "../../services/manager";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import RadioField from "../../components/RadioField";
import SessionScreen from "./SessionScreen";
import usePreviousNCValue from "../../hooks/usePreviousNCValue";
import type { StoredValue as PreviousStoredValue } from "./SessionScreen36";

export type StoredValue = "safety" | "self_esteem" | "responsibility" | "ok" | "competent" | "powerless";

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nc = usePreviousNCValue();
  const [val, setVal] = useState("");
  const [firstState, setFirstState] = useState(true);

  async function answer() {
    await storeAnswer("42", t("screens:42.uuid"), val);
    navigate("/session/43", { replace: true });
  }

  useEffect(() => {
    if (!firstState) {
      return;
    }
    setFirstState(false);
    props.useAudio('screens:42.audio');
    (async function fetchData(): Promise<void> {
      const previousData36 = await getLatestScreenData("36");
      const previousData40 = await getLatestScreenData("40");
      if (previousData40?.answer) {
        if (previousData40.answer == "guilty") {
          await storeAnswer("42", t("screens:42.uuid"), "responsibility");
          navigate("/session/43", { replace: true });
        } else if (previousData40.answer == "unsafe") {
          await storeAnswer("42", t("screens:42.uuid"), "safety");
          navigate("/session/43", { replace: true });
        } else if (previousData40.answer === "powerless") {
          await storeAnswer("42", t("screens:42.uuid"), "powerless");
          navigate("/session/43", { replace: true });
        }
      }
      else if (previousData36?.answer) {
        const answers36 = previousData36.answer as PreviousStoredValue;
        if (answers36.powerless === "yes") {
          await storeAnswer("42", t("screens:42.uuid"), "powerless");
          navigate("/session/43", { replace: true });
        }
        else if (answers36.guilty === "yes") {
          await storeAnswer("42", t("screens:42.uuid"), "responsibility");
          navigate("/session/43", { replace: true });
        }
        else if (answers36.unsafe  === "yes") {
          await storeAnswer("42", t("screens:42.uuid"), "safety");
          navigate("/session/43", { replace: true });
        }
      }
    })();
  });

  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        disabled={val === ""}
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="42"
      title={`${t("screens:42.step")} ${t("screens:42.title")}`}
      chapter={2}
      progress={70}
      audio={t("screens:42.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:42.text", { nc: t(nc) })} />

      <div className="flex gap-4 py-3">
        <RadioField
          id="ok"
          label={t("ok")}
          option="ok"
          value={val}
          group="option"
          onChange={setVal}
        />
        <label htmlFor="ok">
          <span className="font-bold">{t("i_am_ok")}</span>
        </label>
      </div>

      <div className="flex gap-4 py-3">
        <RadioField
          id="self_esteem"
          label={t("self_esteem")}
          option="self_esteem"
          value={val}
          group="option"
          onChange={setVal}
        />
        <label htmlFor="self_esteem">
          <span className="font-bold">{t("i_am_worthy")}</span>
        </label>
      </div>

      <div className="flex gap-4 py-3">
        <RadioField
          id="competent"
          label={t("competent")}
          option="competent"
          value={val}
          group="option"
          onChange={setVal}
        />
        <label htmlFor="responsibility">
          <span className="font-bold">{t("i_am_competent")}</span>
        </label>
      </div>
    </SessionScreen>
  );
}
