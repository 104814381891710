import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import { useEffect } from 'react';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer('12', t('screens:12.uuid'));
    navigate('/session/21', { replace: true });
  }
  const actions = (<>
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);
  useEffect(() => {
    props.useAudio('screens:12.audio');
  });
  return (
    <SessionScreen
      screen="12"
      title={`${t('screens:12.step')} ${t('screens:12.title')}`}
      chapter={2}
      progress={40}
      audio={t('screens:12.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:12.text')} />
    </SessionScreen>
  );
};
