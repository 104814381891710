import { getLatestScreenData, storeAnswer } from "../../services/manager";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import OptionField from "../../components/OptionField";
import type { StoredValue as PreviousStoredValue } from "./SessionScreen36";
import type { StoredValue as PreviousStoredValueCustomNC } from "./SessionScreen34";


export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const [nc, setNC] = useState("");

  async function answer() {
    await storeAnswer("39", t("screens:39.uuid"), val);
    if (val === "yes") {
      navigate(`/session/42`, { replace: true });
    } else {
      if (val === "no") navigate("/session/36", { replace: true });
    }
  }
  

  useEffect(() => {
    props.useAudio('screens:39.audio');
    (async function fetchData(): Promise<void> {

      let previousData34 = await getLatestScreenData("34");
      let previousData36 = await getLatestScreenData("36");
      let previousData37 = await getLatestScreenData("37");
      let previousData39 = await getLatestScreenData("39");
      let previousData40 = await getLatestScreenData("40");

      //remove old data that we don't need atm
      if (previousData39 != null) {
        if (previousData34 != null && previousData34.timestamp < previousData39.timestamp) {
          previousData34 = null;
        }
        if (previousData36 != null && previousData36.timestamp < previousData39.timestamp) {
          previousData36 = null;
        }
        if (previousData37 != null && previousData37.timestamp < previousData39.timestamp) {
          previousData37 = null;
        }
        if (previousData40 != null && previousData40.timestamp < previousData39.timestamp) {
          previousData40 = null;
        }
      }

      if (previousData40?.answer) {
        return setNC(t(previousData40.answer as PreviousStoredValueCustomNC));
      }

      if (previousData36?.answer) {
        const answers36 = previousData36.answer as PreviousStoredValue;
        const answer = Object.entries(answers36).find(([k, v]) => v === "yes");
        if (answer) 
          return setNC(t(answer[0]));
      }

      if (previousData37?.answer) {
        const answers37 = previousData37.answer as PreviousStoredValue;
        const answer = Object.entries(answers37).find(([k, v]) => v === "yes");
        if (answer) 
          return setNC(t(answer[0]));
      }

      if (previousData34?.answer)
        return setNC(previousData34.answer as PreviousStoredValueCustomNC);
    })();
  });

  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        disabled={val === ""}
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="39"
      title={`${t("screens:39.step")} ${t("screens:39.title")}`}
      chapter={2}
      progress={55}
      audio={t("screens:39.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:39.text", { nc })} />
      <div className="flex flex-col gap-2">
        <OptionField
          label={t("yes")}
          option="yes"
          value={val}
          onChange={setVal}
        />
        <OptionField
          label={t("no")}
          option="no"
          value={val}
          onChange={setVal}
        />
      </div>
    </SessionScreen>
  );
}
