import { isPlainObject } from '../services/utils';
import questions from '../data/screens.json';
import type { ResourceLanguage, ResourceKey } from 'i18next';

/**
 * Convert object structure with multiple language definitions into a i18n difinition.
 *
 * Example
 * -------
 *
 * {
 *   "title": {
 *      "nl": "Negatieve herinnering",
 *      "en": "Negative memory"
 *    },
 *    "audio": {
 *      "nl": null,
 *      "en": null
 *    }
 * }
 *
 * Is converted to:
 * {
 *   "title": "Negatieve herinnering",
 *   "audio": null
 * }
 *
 * @param language
 * @param obj
 * @returns
 */
export function convert(language: string, obj: ResourceKey) {
  const converted: ResourceLanguage = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (isPlainObject(value) && value[language] !== undefined) {
      converted[key] = value[language];
    } else if (isPlainObject(value)) {
      converted[key] = convert(language, value);
    } else {
      converted[key] = value;
    }
  });

  return converted;
}

export default function Importer(language: string) {
  return convert(language, questions);
}
