import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactNode;
}

export default function Portal({ children }: Props) {
  return createPortal(
    children,
    document.getElementById('portal-root') as HTMLElement
  );
}
