import { storeAnswer } from "../../services/manager";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import { useEffect } from "react";

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer("23", t("screens:23.uuid"));
    navigate("/session/25", { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:23.audio');
  });

  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="23"
      title={`${t("screens:23.step")} ${t("screens:23.title")}`}
      chapter={2}
      progress={10}
      audio={t("screens:23.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:23.text")} />
    </SessionScreen>
  );
}
