import { ChangeEvent, useEffect, useState } from 'react';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import classNames from 'classnames';

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

type Props = {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  label: string;
  onChange: (val: string) => void;
  optionOn: string;
  optionOff?: string
  value: string;
  variant?: 'primary';
}

export default function CheckField({
  className,
  label,
  onChange,
  optionOn,
  optionOff,
  value,
  variant = 'primary',
  ...rest
}: Props) {

  const [isChecked, setCheckedState] = useState<boolean>(false);

  useEffect(() => {
    setCheckedState(value === optionOn);
  }, [value, optionOn]);

  const handleChange = (event: InputChangeEvent) => {
    setCheckedState(event.target.value === optionOn);
    onChange(event.target.value);
  };

  const classes = classNames(
    'block border rounded-sm h-4 w-4 cursor-pointer text-center overflow-hidden relative',
    className,
    {
      // Primary
      'border-lightblue': variant === 'primary',
      'bg-lightblue': variant === 'primary' && isChecked,
    },
  );

  return (
    <label className={classes} aria-label={label}>
      <input
        checked={isChecked}
        className="sr-only"
        onChange={handleChange}
        type="checkbox"
        value={
          isChecked ? (
            optionOff !== undefined ? optionOff : optionOn
          ) : optionOn
        }
        {...rest}
      />
      <CheckIcon className="absolute text-white left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[0.7rem]" />
    </label>
  );
};
