import type { MouseEvent, TouchEvent } from 'react';

export type Coords = {
  x: number;
  y: number;
};

export function getWindowPageOffset(): Coords {
  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  };
};

export function getNormalizedEventCoords(evt: MouseEvent | TouchEvent | undefined, pageOffset: Coords, clientRect: DOMRect): Coords {
  if (!evt) return { x: 0, y: 0 };

  const { x, y } = pageOffset;
  const documentX = x + clientRect.left;
  const documentY = y + clientRect.top;

  let normalizedX;
  let normalizedY;

  if (evt.type === 'touchstart') {
    const touchEvent = evt as TouchEvent;
    normalizedX = touchEvent.changedTouches[0].pageX - documentX;
    normalizedY = touchEvent.changedTouches[0].pageY - documentY;
  } else {
    const mouseEvent = evt as MouseEvent;
    normalizedX = (mouseEvent.pageX || mouseEvent.clientX || 0) - documentX;
    normalizedY = (mouseEvent.pageY || mouseEvent.clientY || 0) - documentY;
  }

  return { x: normalizedX, y: normalizedY };
}
