import { ChangeEvent, ReactNode } from 'react';
import classNames from 'classnames';

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

type Props = {
  autoFocus?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  group?: string;
  label: string;
  onChange: (val: string) => void;
  option: string;
  size?: 'normal' | 'small';
  value: string;
  variant?: 'primary' | 'secondary';
}

export default function OptionField({
  children,
  className,
  group = 'default',
  label,
  onChange,
  option,
  size = 'normal',
  value,
  variant = 'primary',
  ...rest
}: Props) {

  const handleChange = (event: InputChangeEvent) => {
    onChange(event.target.value);
  };

  const checked = (value === option);

  const classes = classNames(
    'block border rounded cursor-pointer text-center overflow-auto relative uppercase',
    className,
    {
      // Sizes
      'py-1 px-2 text-xs min-w-[6rem]': size === 'small',
      'py-2 px-6 text-sm font-bold': size === 'normal',
    },
    {
      // Primary
      'border-lightblue': variant === 'primary',
      'bg-lightblue text-white': variant === 'primary' && checked,
      'text-lightblue': variant === 'primary' && !checked,
    },
    {
      // Secondary
      'border-blue': variant === 'secondary',
      'bg-blue text-white': variant === 'secondary' && checked,
      'text-blue': variant === 'secondary' && !checked,
    }
  );

  return (
    <label className={classes} aria-label={label}>
      <input
        checked={checked}
        className="sr-only"
        name={group}
        onChange={handleChange}
        type="radio"
        value={option}
        {...rest}
      />
      {children ? children : label}
    </label>
  );
};
