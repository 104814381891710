import { storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('13', t('screens:13.uuid'), val);
    if (val === 'there_is_another_picture') navigate('/session/12', { replace: true });
    if (val === 'this_picture_is_not_the_worst') navigate('/session/14', { replace: true });
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

useEffect(() => {
  props.useAudio('screens:13.audio');
});
  return (
    <SessionScreen
      screen="13"
      title={`${t('screens:13.step')} ${t('screens:13.title')}`}
      chapter={2}
      progress={40}
      audio={t('screens:13.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:13.text')} />
      <div className="flex flex-col gap-2">
        <OptionField label={t('there_is_another_picture')} option="there_is_another_picture" value={val} onChange={setVal} />
        <OptionField label={t('this_picture_is_not_the_worst')} option="this_picture_is_not_the_worst" value={val} onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
