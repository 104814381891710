import { clearSession, finishSession, processQueue, storeAnswer } from '../../services/manager';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    (async function() {
      await storeAnswer('33', t('screens:33.uuid'));
      await processQueue();
      await finishSession();
    })();
  });

  async function answer() {
    await clearSession();
    navigate('/', { replace: true });
  }

  async function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    props.useAudio('screens:33.audio');
  });

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('exit')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="33"
      title={`${t('screens:33.step')} ${t('screens:33.title')}`}
      chapter={2}
      progress={100}
      audio={t('screens:33.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:33.text')} />
    </SessionScreen>
  );
};
