import { storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';
import TextField from "../../components/TextField";
import useValidation from "../../hooks/useValidation";
import TextareaField from '../../components/TextareaField';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');
  const validation = useValidation(val, "min:3");

  async function answer() {
    await storeAnswer('74', t('screens:74.uuid'), val);
    navigate('/session/75', { replace: true });
    return;
  }
  
  useEffect(() => {
    props.useAudio('screens:74.audio');
  });

  const actions = (<>
    <Button label={t('continue')}
     variant="primary" 
     disabled={validation.failed}
     onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="74"
      title={`${t('screens:74.step')} ${t('screens:74.title')}`}
      chapter={6}
      progress={0}
      audio={t('screens:74.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:74.text')} />

      <TextareaField
        className='h-24vh'
        value={val}
        onChange={setVal}
        placeholder={t("write_your_answer_here")}
      />
    </SessionScreen>
  );
};
