import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import { useEffect } from 'react';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer('14', t('screens:14.uuid'));
    navigate('/session/15');
  }

  const actions = (<>
    <Button label={t('explanation_2_of_2')} variant="primary" onClick={() => answer()} />
  </>);

useEffect(() => {
  props.useAudio('screens:14.audio');
});
  return (
    <SessionScreen
      screen="14"
      title={`${t('screens:14.step')} ${t('screens:14.title')}`}
      chapter={2}
      progress={40}
      audio={t('screens:14.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:14.text')} />
    </SessionScreen>
  );
};
