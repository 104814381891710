import { storeAnswer } from "../../services/manager";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import { useEffect } from "react";

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer("35", t("screens:35.uuid"));
    navigate("/session/36", { replace: true });
  }
  
  useEffect(() => {
    props.useAudio('screens:35.audio');
  });
  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="35"
      title={`${t("screens:35.step")} ${t("screens:35.title")}`}
      chapter={2}
      progress={50}
      audio={t("screens:35.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:35.text")} />
    </SessionScreen>
  );
}
