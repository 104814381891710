import en from "./en.json";
import nl from "./nl.json";
import de from "./de.json";
import importer from "./importer";

const translations = {
  en: { base: en, screens: importer("en") },
  nl: { base: nl, screens: importer("nl") },
  de: { base: de, screens: importer("de") },
};

export default translations;
