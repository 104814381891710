import { t } from 'i18next';
import { ChangeEvent } from 'react';

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  name?: string;
  onChange: (val: string) => void;
  value: string;
}

export default function RespondentTextField({
  onChange,
  value,
  ...rest
}: Props) {

  const handleChange = (event: InputChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <div className="relative border-b border-l border-r border-blue">
      <input
        id="respondentnumber"
        className="peer block w-full h-14 px-6 border-none text-xl font-bold outline-none placeholder-transparent"
        onChange={handleChange}
        onInput={handleChange}
        placeholder={t("your_name")}
        type="text"
        value={value}
        {...rest}
      />

      <div className="absolute left-0 top-0 border-t border-blue w-4"></div>
      <div className="absolute left-[9.2rem] right-0 top-0 border-t border-blue"></div>
      <div className="absolute inset-x-0 top-0 border-t border-blue opacity-0 transition-opacity peer-placeholder-shown:opacity-100 peer-focus:opacity-0"></div>

      <label htmlFor="respondentnumber" className={`
        absolute left-4 -top-2 text-blue text-sm px-1 transition-all max-w-full truncate opacity-0 italic
        peer-placeholder-shown:left-6 peer-placeholder-shown:top-4.5 peer-placeholder-shown:text-lg peer-placeholder-shown:text-gray-400 peer-placeholder-shown:opacity-100
        peer-focus:left-4 peer-focus:-top-2 peer-focus:text-sm peer-focus:text-blue peer-focus:opacity-0
      `}>
        {t("name_of_your_therapist")}
      </label>
      <label htmlFor="respondentnumber" className={`
        absolute left-4 -top-2 text-blue text-sm px-1 transition-all max-w-full truncate opacity-100 pointer-events-none italic
        peer-placeholder-shown:left-6 peer-placeholder-shown:top-4 peer-placeholder-shown:text-lg peer-placeholder-shown:text-gray-400 peer-placeholder-shown:opacity-0
        peer-focus:left-4 peer-focus:-top-2 peer-focus:text-sm peer-focus:text-blue peer-focus:opacity-100
      `}>
      {t("name_of_your_therapist")}
      </label>
    </div>
  );
};
