import classNames from 'classnames';
import { ChangeEvent } from 'react';
import LimitIndicator from './LimitIndicator';

type InputChangeEvent = ChangeEvent<HTMLTextAreaElement>;

type Props = {
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  maxLength?: number;
  name?: string;
  onChange: (val: string) => void;
  placeholder?: string;
  value: string;
}

export default function TextareaField({
  className,
  maxLength,
  onChange,
  value,
  ...rest
}: Props) {

  const handleChange = (event: InputChangeEvent) => {
    onChange(event.target.value);
  };

  const classes = classNames(
    'block w-full py-2 px-3 border border-blue rounded text-sm resize-none outline-none placeholder:italic',
    {
      'h-32': className !== 'h-24vh',
      'h-24vh': className === 'h-24vh',
    },
  );

  return (
    <>
      <div className='textarea-container'>
        <textarea
          className={classes}
          maxLength={maxLength}
          onChange={handleChange}
          value={value}
          {...rest}
        />
      </div>

      { maxLength && <LimitIndicator value={value.length} max={maxLength} />}
    </>
  );
};
