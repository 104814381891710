import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { storeView } from '../services/manager';

export default function useStoreScreenView(screen: string): void {
  const { pathname } = useLocation();

  useEffect(() => {
    storeView(screen, pathname);
  }, [screen, pathname]);
};
