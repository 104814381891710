type Props = {
  max: number;
  value: number;
};

export default function LimitIndicator({ max, value = 0 }: Props) {
  return (
    <div className="text-right text-sm py-1">
      {value}/{max}
    </div>
  );
};
