import { storeAnswer } from "../../services/manager";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import OptionField from "../../components/OptionField";

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState("");

  async function answer() {
    await storeAnswer("28", t("screens:28.uuid"), val);
    if (val === "yes") {
      navigate("/session/30", { replace: true });
    } else {
      navigate("/session/14", { replace: true });
    }
  }

  useEffect(() => {
    props.useAudio('screens:28.audio');
  });

  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        disabled={val === ""}
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="28"
      title={`${t("screens:28.step")} ${t("screens:28.title")}`}
      chapter={2}
      progress={35}
      audio={t("screens:28.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:28.text")} />
      <div className="flex flex-col gap-2">
        <OptionField
          label={t("yes")}
          option="yes"
          value={val}
          onChange={setVal}
        />
        <OptionField
          label={t("no")}
          option="no"
          value={val}
          onChange={setVal}
        />
      </div>
    </SessionScreen>
  );
}
