import { storeAnswer } from "../../services/manager";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";
import SessionScreen from "./SessionScreen";
import CheckField from "../../components/CheckField";
import usePreviousNCValue from "../../hooks/usePreviousNCValue";
import Dialog from "../../components/Dialog";

export type StoredValue = {
  angry: "yes" | "no";
  anxious: "yes" | "no";
  sad: "yes" | "no";
  disgust: "yes" | "no";
  shame: "yes" | "no";
};

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nc = usePreviousNCValue();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [checkedValues, setCheckedValue] = useState<StoredValue>({
    angry: "no",
    anxious: "no",
    sad: "no",
    disgust: "no",
    shame: "no",
  });

  function handleCheck(key: string, state: string): void {
    setCheckedValue({
      ...checkedValues,
      [key as keyof StoredValue]: state,
    });
  }

  async function answer() {
    if (Object.values(checkedValues).filter((v) => v === "yes").length === 0) {
      setErrorMessage(
        t(
          "select_at_least_1_emotion_that_most_suits_you_the_emotion_you_are_feeling_right_now"
        )
      );
      return;
    }

    await storeAnswer("44", t("screens:44.uuid"), checkedValues);
    navigate("/session/45", { replace: true });
  }  
  useEffect(() => {
    props.useAudio('screens:44.audio');
  });

  const actions = (
    <>
      <Button
        label={t("continue")}
        variant="primary"
        onClick={() => answer()}
      />
    </>
  );

  return (
    <SessionScreen
      screen="44"
      title={`${t("screens:44.step")} ${t("screens:44.title")}`}
      chapter={2}
      progress={80}
      audio={t("screens:44.audio")}
      actions={actions}
    >
      <Markdown value={t("screens:44.text", { nc: t(nc) })} />

      <div className="flex flex-col gap-4 mt-10">
        <div className="flex gap-4">
          <CheckField
            id="angry"
            label={t("angry")}
            optionOn="yes"
            optionOff="no"
            value={checkedValues.angry}
            onChange={(value) => handleCheck("angry", value)}
          />
          <label htmlFor="angry">{t("angry")}</label>
        </div>
        <div className="flex gap-4">
          <CheckField
            id="anxious"
            label={t("anxious")}
            optionOn="yes"
            optionOff="no"
            value={checkedValues.anxious}
            onChange={(value) => handleCheck("anxious", value)}
          />
          <label htmlFor="anxious">{t("anxious")}</label>
        </div>
        <div className="flex gap-4">
          <CheckField
            id="sad"
            label={t("sad")}
            optionOn="yes"
            optionOff="no"
            value={checkedValues.sad}
            onChange={(value) => handleCheck("sad", value)}
          />
          <label htmlFor="sad">{t("sad")}</label>
        </div>
        <div className="flex gap-4">
          <CheckField
            id="disgust"
            label={t("disgust")}
            optionOn="yes"
            optionOff="no"
            value={checkedValues.disgust}
            onChange={(value) => handleCheck("disgust", value)}
          />
          <label htmlFor="disgust">{t("disgust")}</label>
        </div>
        <div className="flex gap-4">
          <CheckField
            id="shame"
            label={t("shame")}
            optionOn="yes"
            optionOff="no"
            value={checkedValues.shame}
            onChange={(value) => handleCheck("shame", value)}
          />
          <label htmlFor="shame">{t("shame")}</label>
        </div>
      </div>

      {errorMessage && (
        <Dialog
          actions={
            <Button onClick={() => setErrorMessage(null)} label={t("ok")} />
          }
        >
          {errorMessage}
        </Dialog>
      )}
    </SessionScreen>
  );
}
