import { ChangeEvent } from 'react';
import classNames from 'classnames';

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

type Props = {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  group?: string;
  id?: string;
  label: string;
  onChange: (val: string) => void;
  option: string;
  value: string;
  variant?: 'primary';
}

export default function RadioField({
  className,
  group = 'default',
  label,
  onChange,
  option,
  value,
  variant = 'primary',
  ...rest
}: Props) {

  const handleChange = (event: InputChangeEvent) => {
    onChange(event.target.value);
  };

  const checked = (value === option);

  const classes = classNames(
    'block border-2 rounded-full h-5 w-5 cursor-pointer text-center overflow-hidden relative',
    'after:block after:rounded-full after:h-2.5 after:w-2.5 after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2',
    className,
    {
      // Primary
      'border-blue': variant === 'primary',
      'after:bg-blue': variant === 'primary' && checked,
    },
  );

  return (
    <label className={classes} aria-label={label}>
      <input
        checked={checked}
        className="sr-only"
        name={group}
        onChange={handleChange}
        type="radio"
        value={option}
        {...rest}
      />
    </label>
  );
};
