import { makeAnswer, submitAnswer, finishSession as callFinishSession, submitAnswers } from './api';
import { v4 as uuid } from 'uuid';
import type { Answer, AnswerValue } from './api';

type Entry = {
  content: Answer;
  processed: 'yes' | 'no';
  screen: string;
  session_id: string;
}

type StoredEntry = Entry & {
  id: number;
}

type ViewEntry = {
  screen: string;
  path: string;
  session_id: string;
}

type StoredViewEntry = ViewEntry & {
  id: number;
}

/**
 * Get active session id.
 */
export function getSession(): string | null {
  return sessionStorage.getItem('session');
}

/**
 * Get active session id.
 */
export function setSession(session_id: string): void {
  sessionStorage.setItem('session', session_id);
}

/**
 * Create and return a new session id.
 */
export function createSessionId(): string {
  return uuid();
}

/**
 * Wipe out all session data.
 */
export async function clearSession() {
  await clearSessionDataFromStore('views');
  await clearSessionDataFromStore('entities');
  sessionStorage.clear();
}

/**
 * Connect IndexedDB and migrate
 */
async function connect(): Promise<IDBOpenDBRequest> {
  return new Promise((resolve, reject) => {
    const db = indexedDB.open('data', 10);

    db.onblocked = event => reject(event);
    db.onerror = event => reject(event);
    db.onsuccess = (event: Event) => resolve(event.target as IDBOpenDBRequest);

    db.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const target = event.target as IDBOpenDBRequest;
      const instance = target.result;

      /**
       * Entities store
       */
      const entitiesStore = !instance.objectStoreNames.contains('entities') ?
        instance.createObjectStore('entities', { keyPath: 'id', autoIncrement: true }) :
        target.transaction?.objectStore('entities');

      if (entitiesStore && !entitiesStore.indexNames.contains('sessionIndex')) {
        entitiesStore.createIndex('sessionIndex', 'session_id', { unique: false });
      }
      if (entitiesStore && !entitiesStore.indexNames.contains('sessionProcessed')) {
        entitiesStore.createIndex('sessionProcessed', ['session_id', 'processed'], { unique: false });
      }
      if (entitiesStore && !entitiesStore.indexNames.contains('sessionScreen')) {
        entitiesStore.createIndex('sessionScreen', ['session_id', 'screen'], { unique: false });
      }

      /**
       * Views store
       */
      const viewsStore = !instance.objectStoreNames.contains('views') ?
        instance.createObjectStore('views', { keyPath: 'id', autoIncrement: true }) :
        target.transaction?.objectStore('views');

      if (viewsStore && !viewsStore.indexNames.contains('sessionIndex')) {
        viewsStore.createIndex('sessionIndex', 'session_id', { unique: false });
      }
      if (viewsStore && !viewsStore.indexNames.contains('sessionScreen')) {
        viewsStore.createIndex('sessionScreen', ['session_id', 'screen'], { unique: false });
      }
    };
  });
};

/**
 * Store answer screen data into the database and try to process.
 */
export async function storeAnswer(screen: string, question_id: string, answer: AnswerValue = ''): Promise<void> {
  const session_id = getSession();
  if (!session_id) return;

  return new Promise(async (resolve, reject) => {
    const db = await connect();
    const transaction = db.result.transaction('entities', 'readwrite');
    const store = transaction.objectStore('entities');

    const entry: Entry = {
      content: makeAnswer(question_id, answer),
      processed: 'no',
      screen,
      session_id,
    };

    const create = store.add(entry);
    create.onerror = event => reject(event);
    create.onsuccess = async (event) => {
      const target = event.target as IDBRequest;
      const id = target.result;

      try {
        await submitAnswer(session_id, entry.content);
        await markAsProcessed(id);
      } catch (error) {
        console.error(error);
      }

      resolve();
    };
  });
};

/**
 * Mark a processable entry as processed.
 */
export async function markAsProcessed(key: number): Promise<void> {
  return new Promise(async (resolve, reject) => {
    const db = await connect();
    const transaction = db.result.transaction('entities', 'readwrite');
    const store = transaction.objectStore('entities');

    const find = store.get(key);
    find.onerror = () => reject();
    find.onsuccess = event => {
      const target = event.target as IDBRequest;

      const entry = target.result as Entry;
      entry.processed = 'yes';

      const update = store.put(entry);
      update.onerror = () => reject();
      update.onsuccess = () => resolve();
    };
  });
};

/**
 * Get the latest data from given screen.
 */
export async function getLatestScreenData(screen: string): Promise<Answer | null> {
  const session_id = getSession();
  if (!session_id) return null;

  return new Promise(async (resolve, reject) => {
    const db = await connect();
    const transaction = db.result.transaction('entities', 'readonly');
    const store = transaction.objectStore('entities');
    const sessionIndex = store.index('sessionScreen');
    const cursor = sessionIndex.openCursor([session_id, screen], 'prev');

    cursor.onerror = event => reject(event);
    cursor.onsuccess = event => {
      const target = event.target as IDBRequest;
      const entryCursor = target.result;

      if (entryCursor) {
        const entry = entryCursor.value as Entry;
        resolve(entry.content);
      } else {
        resolve(null);
      }
    };
  });
};

/**
 * Process queue. submit all unprocessed answers.
 */
export async function processQueue(): Promise<boolean> {
  const session_id = getSession();
  if (!session_id) return false;

  return new Promise(async (resolve, reject) => {
    const db = await connect();
    const transaction = db.result.transaction('entities', 'readonly');
    const store = transaction.objectStore('entities');
    const queueIndex = store.index('sessionProcessed');
    const query = queueIndex.getAll([session_id, 'no']);

    let results: Array<StoredEntry> = [];

    query.onerror = event => reject(event);
    query.onsuccess = event => {
      const target = event.target as IDBRequest;
      results = target.result as Array<StoredEntry>;
    };

    transaction.onerror = event => reject(event);
    transaction.oncomplete = async (event) => {
      const answers = results.map(result => result.content);

      try {
        // Submit all answers.
        await submitAnswers(session_id, answers);

        // Mark all entries as processed.
        results.forEach(result => markAsProcessed(result.id));

        resolve(true);
      } catch (error) {
        resolve(false);
      }
    };
  });
}

/**
 * Store a screen view.
 */
export async function storeView(screen: string, path: string): Promise<void> {
  const session_id = getSession();
  if (!session_id) return;

  const db = await connect();
  const transaction = db.result.transaction('views', 'readwrite');
  const store = transaction.objectStore('views');
  store.add({ session_id, screen, path } as ViewEntry);
}

/**
 * Get last viewed screen.
 */
 export async function getLastViewedScreen(screen?: string): Promise<StoredViewEntry | null> {
  const session_id = getSession();
  if (!session_id) return null;

  return new Promise(async (resolve, reject) => {
    const db = await connect();
    const transaction = db.result.transaction('views', 'readonly');
    const store = transaction.objectStore('views');

    const cursor = screen ?
      store.index('sessionScreen').openCursor([session_id, screen], 'prev') :
      store.openCursor(null, 'prev');

    cursor.onerror = event => reject(event);
    cursor.onsuccess = event => {
      const target = event.target as IDBRequest;
      const entryCursor = target.result;

      if (entryCursor) {
        const entry = entryCursor.value as StoredViewEntry;
        resolve(entry);
      } else {
        resolve(null);
      }
    };
  });
};

export async function finishSession(): Promise<void> {
  const session_id = getSession();
  if (!session_id) return;

  await callFinishSession(session_id);
}

async function clearSessionDataFromStore(storeName: string): Promise<void> {
  return new Promise(async (resolve, reject) => {
    const session_id = getSession();
    if (!session_id) return resolve();

    const db = await connect();
    const transaction = db.result.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const cursor = store.index('sessionIndex').openCursor(session_id);
    cursor.onerror = event => reject(event);
    cursor.onsuccess = event => {
      const target = event.target as IDBRequest;
      const result = target.result;
      if (result) {
        result.delete();
        result.continue();
      }
    };
    transaction.onerror = event => reject(event);
    transaction.oncomplete = event => resolve();
  });
}
