import {compiler} from 'markdown-to-jsx';

type Props = {
  value: string;
  overrides?: any;
}

const defaultOverrides = {
  p: {
    props: {
      className: 'mb-4',
    },
  },
  li: {
    props: {
      'data-before': '►',
      className: 'my-4 before:content-[attr(data-before)] before:inline-block before:mr-2'
    }
  }
};

export default function Markdown({ value, overrides }: Props) {
  console.log(overrides, defaultOverrides, {...defaultOverrides, ...overrides})
  return compiler(value, {
    wrapper: null,
    forceBlock: true,
    overrides: {...defaultOverrides, ...overrides},

    /**
     * We need this to underline text, since it is not supported by markdown,
     * we have to wait for this pull request to be merged so we can extend the rules
     * to create a custom underline parser.
     *
     * https://github.com/probablyup/markdown-to-jsx/pull/406
     */
    disableParsingRawHTML: false,
  });
}
