import { getNormalizedEventCoords, getWindowPageOffset } from '../utils';
import { ReactElement, ReactNode, useRef, useState, cloneElement } from 'react';
import classNames from 'classnames';
import type { Coords } from '../utils';
import type { MouseEvent } from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: ReactElement;
  label: string;
  normalcase?: boolean;
  onClick?(event: MouseEvent<HTMLElement>): void;
  shadow?: boolean;
  size?: 'normal' | 'small' | 'large';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary';
}

export default function Button({
  children,
  className,
  disabled = false,
  icon,
  label,
  normalcase = false,
  onClick,
  shadow = false,
  size = 'normal',
  variant = 'primary',
}: Props) {

  const [rippleCoords, setRippleCoords] = useState<Coords>({ x: 0, y: 0 });
  const [isRippling, setRippling] = useState<boolean>(false);
  const timeout = useRef<number>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;

    if (!isRippling) {
      setRippleCoords(getNormalizedEventCoords(event, getWindowPageOffset(), target.getBoundingClientRect()));
      setRippling(true);
    }

    if (onClick) {
      clearTimeout(timeout.current);

      // Give the ripple some time to animate.
      timeout.current = window.setTimeout(() => onClick(event), 310);
    }
  };

  const onRippleEnd = () => {
    setRippling(false);
  };

  const rippleClasses = classNames(
    'absolute block rounded-full bg-white w-4 h-4 top-1/2 left-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2 opacity-0',
    {
      'animate-ripple': isRippling,
      'bg-white': variant === 'primary',
      'bg-blue/50': variant === 'secondary',
      'bg-lightblue/50': variant === 'tertiary',
      'bg-blue/25 ': variant === 'quaternary',
    }
  );

  const themeClasses = classNames(
    'relative inline-block border overflow-hidden text-center tracking-widest',
    className,
    {
      // TextTransform
      'uppercase': !normalcase,
    },
    {
      // Sizes
      'py-1 px-2 text-xs rounded-sm': size === 'small',
      'py-2 px-2.5 text-xs font-bold rounded-md min-w-[8rem]': size === 'normal',
      'py-4 px-6 text-lg font-bold rounded-md': size === 'large',
    },
    {
      // Shadow
      'shadow': ['small', 'normal'].includes(size) && shadow,
      'shadow-xl': size === 'large' && shadow,
    },
    {
      // Primary
      'bg-babyblue text-white border-babyblue': variant === 'primary',
      'bg-opacity-20 text-blue/50 border-opacity-0': variant === 'primary' && disabled,
    },
    {
      // Secondary
      'bg-transparent text-blue border-blue': variant === 'secondary',
      'border-opacity-50 text-opacity-50': variant === 'secondary' && disabled,
    },
    {
      // Tertiary
      'bg-transparent text-lightblue border-lightblue': variant === 'tertiary',
      'border-opacity-50 text-opacity-50': variant === 'tertiary' && disabled,
    },
    {
      // Quinary
      'bg-lightblue text-white border-lightblue': variant === 'quinary',
      'border-opacity-50 text-opacity-50': variant === 'quinary' && disabled,
    },
    {
      // Quaternary
      'bg-white text-blue border-transparent': variant === 'quaternary',
      'text-opacity-50': variant === 'quaternary' && disabled,
    },
  );

  const Icon = ({ ...props }) => {
    props.className = classNames(
      props.className,
      icon?.props.className
    );
    return icon ? cloneElement(icon, props) : null;
  };

  return (
    <button type="button" className={themeClasses} disabled={disabled} aria-label={label} onClick={handleClick}>
      <span role="presentation" className={rippleClasses} style={{ left: rippleCoords.x, top: rippleCoords.y}} onAnimationEnd={onRippleEnd}></span>
      <span className="relative block">
        <Icon className="h-3 align-text-top inline-block mr-2 pointer-events-none" />
        {children ? children : label}
      </span>
    </button>
  );
};
