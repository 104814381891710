import { storeAnswer } from '../../services/manager';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import OptionField from '../../components/OptionField';

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('17', t('screens:17.uuid'), val);
    if (val === 'yes') navigate('/session/18', { replace: true });
    if (val === 'no') navigate('/session/19', { replace: true });
  }

  useEffect(() => {
    props.useAudio('screens:17.audio');
  });

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="17"
      title={`${t('screens:17.step')} ${t('screens:17.title')}`}
      chapter={2}
      progress={40}
      audio={t('screens:17.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:17.text')} />
      <div className="flex flex-col gap-2">
        <OptionField label={t('yes')} option="yes" value={val} onChange={setVal} />
        <OptionField label={t('no')} option="no" value={val} onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
