import { ChangeEvent } from 'react';
import LimitIndicator from './LimitIndicator';

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  maxLength?: number;
  name?: string;
  onChange: (val: string) => void;
  placeholder?: string;
  value: string;
}

export default function TextField({
  maxLength,
  onChange,
  value,
  ...rest
}: Props) {

  const handleChange = (event: InputChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <>
      <input
        className="block w-full py-2 px-3 border border-blue rounded text-sm outline-none placeholder:italic"
        maxLength={maxLength}
        onChange={handleChange}
        type="text"
        value={value}
        {...rest}
      />

      { maxLength && <LimitIndicator value={value.length} max={maxLength} />}
    </>
  );
};
